// External
import { useState, FC } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { Box } from '@mui/system';
import { LicenseInfo } from '@mui/x-license-pro';

// Styles
import {lightTheme, darkTheme } from './styles/theme'
import CssBaseline from '@mui/material/CssBaseline';

// Hooks
import useScript from './hooks/useScript';

// Local
import AppRoutes from './routes/routes';

// For Mui Licence Key (2024/12/17)
LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENCE_KEY as string);

const App: FC = () => {
  const prefersDark: boolean = window.matchMedia('(prefers-color-scheme: dark)').matches
  const [open, setOpen] = useState(false);
  const [darkMode, setDarkMode] = useState(prefersDark)

  return (
    <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppRoutes 
          open={open}
          setOpen={setOpen}
          setDarkMode={setDarkMode}
          darkMode={darkMode}
        />
      </Box>
    </ThemeProvider>
  );
}

export default App;
